<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/trade.png" alt />
        <span class="center">财务功能</span>
        <img src="../../assets/right.png" alt />
        <span class="center">查看充值记录</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字：</span>
        <el-input
          placeholder="付款方/充值方/金额"
          v-model="keywords"
          @input="rechargeSearchList()"
          size="mini"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>
      </div>
    </div>

    <div class="table-box">
      <div>
        <el-table :data="rechargeList" ref="multipleTable" style="width: 100%">
          <el-table-column label="充值编号" width="300">
            <template slot-scope="scope">
              <div>
                <span style="font-weight: 550">
                  {{
                  scope.row.payerCardName
                  }}
                </span>
              </div>
              <div>
                <span>{{ scope.row.notify_id }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="充值金额(元)" prop="remitAmount" width="120">
            <template slot-scope="scope">{{ scope.row.remitAmount | money }}</template>
          </el-table-column>
          <el-table-column label="付款方卡号" prop="payerCardNo" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="收款方" prop="payeeCardName" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="收款方卡号" prop="payeeCardNo" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="充值备注" prop="payerRemark" width="160"></el-table-column>
          <el-table-column label="通知类型" width="120">
            <template slot-scope="scope">{{ scope.row.notify_type | notifyTypeVal }}</template>
          </el-table-column>
          <el-table-column label="通知时间">
            <template slot-scope="scope">{{ scope.row.notify_time }}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { api } from '../../api/base/index';
export const List = api('/trade')('list.consuming.records.json');
export default {
  components: {},
  data () {
    return {
      keywords: '',
      rechargeList: [],
      pageNumber: 1,
      pageSize: 10,

      total: 0
    };
  },
  created () {
    this.getRechargeList();
  },
  methods: {
    async getRechargeList () {
      const res = await List({
        keywords: this.keywords,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        $ignoreRepeat: true
      });
      this.rechargeList = res.list || [];
      this.total = res.total || 0;
    },
    rechargeSearchList () {
      this.pageNumber = 1;
      this.getRechargeList();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.getRechargeList();
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.getRechargeList();
    },
    pageChange (val) {
      this.pageNumber = val;
      this.getRechargeList();
    },
    resetSearch () {
      this.pageNumber = 1;
      this.keywords = '';
      this.getRechargeList();
    },
    putAway () {
      this.keywords = '';
      this.pageNumber = 1;
      this.getRechargeList();
    }
  },
  filters: {
    notifyTypeVal (val) {
      var map = {
        trade_status_sync: '交易通知',
        pay_status_sync: '支付通知',
        transfer_status_sync: '转账通知',
        remit_sync: '子账户汇入',
        withdrawal_status_sync: '单笔提现',
        BATCHED_WITHDRAWAL_STATUS_SYNC: '批量提现',
        CUSTOMER_RECHARGE: '子账户充值',
        BALANCE_CHANGE: '子账户金额变动',
        LIVETRADE_RESULT: '子账户资金调拨交易结果通知',
        CUSTOMER_WITHDRAW: '子账户提现成功通知',
      };
      return map[val] || '';
    }
  }
};
</script>

<style scoped></style>
